$(function(){

	var hdHeight = $(window).height() - 93;
	$('.cHome-hd').height(hdHeight)

	$('.cWelcome__btn').click(function(){
		$('#welcome').addClass('hide')
	})

	$(window).resize(function(){
		var hdHeight = $(window).height() - 93;
		$('.cHome-hd').height(hdHeight)
	})

	$('.bxslider').bxSlider({
		mode: 'fade',
		infiniteLoop: true,
		responsive: true,
		preloadImages: 'visible',
		autoStart: true,
		pause: 2000,
		slideWidth: 0,
		onSliderLoad: autoPlay,
		onSlideBefore: autoPlayWrapper
	});

	function autoPlayWrapper(asdf,asdf,index){
		autoPlay(index);
	}

	// play the video in the slide at index
	function autoPlay(index){
		var idx = parseInt(index,10);
		var vid = document.querySelector("#vid"+idx);
		if(vid){
			vid.play();
		}
	}

	$('.bx-next').css('right', '20px');
	$('.bx-prev').css('left', '20px');

	// OBRIR ITEM AL FER CLICK

	$('.jsProd').click(function(){
		if(!$(this).hasClass('is-open')){
			$('.jsProd').removeClass('is-open is-prev is-next');
			$(this).addClass('is-open').prevAll().addClass('is-prev');
			$(this).nextAll().addClass('is-next');
		} else {
			$('.jsProd').removeClass('is-open is-prev is-next');
		}
	})

	// OBRIR ITEM AL FER SCROLL

	var $window = $(window),
	win_height = $window.height();

	$window.on('scroll', obrirItem);

	function obrirItem() {
		if(!$('.jsProd').hasClass('is-open')){
		 	var scrolled = $window.scrollTop();

	 		var $this     = $('.jsProd:nth-child(1)'),
	 			offsetTop = ($this.offset().top) + ($this.height() / 1.5);
			 if (scrolled + win_height > offsetTop) {
				$this.addClass('is-open').prevAll().addClass('is-prev');
				$this.nextAll().addClass('is-next');
			}
		}
	}
	obrirItem();

	// OBRIR ITEM AL FER SCROLL

	// $('.jsProd').hover(function(){
	// 	$('.jsProd').removeClass('is-open is-prev is-next');
	// 	$(this).addClass('is-open').prevAll().addClass('is-prev');
	// 	$(this).nextAll().addClass('is-next');
	// }, function(){
	// 	$('.jsProd').removeClass('is-open is-prev is-next');
	// })

	$(window).scroll(function(){
		if($('body').scrollTop() > 20){
			$('#welcome').addClass('hide')
		}

	})
})
